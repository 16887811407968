<template #pulling="props">
  <div class="annualmeeting">
    <!-- 下拉刷新 -->

    <!-- 头部标题 -->
    <van-sticky :offset-top="0">
      <div class="top">
        {{ txt }}
      </div>
    </van-sticky>
    <!-- 内容 -->
    <div class="content-box">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <!-- 展示效果栏 -->
        <div class="showtable-box">
          <div class="showtable" v-for="(item, index) in arr" :key="index">
            <!-- <div>亲爱滴你好:</div> -->
            <div class="question">{{ item.title }}</div>
            <!-- <div class="questionn">提问人：{{ item.creatorTrueName }}</div> -->
            <!-- 点赞模块样式 -->
            <div class="zanicon">
              <!-- 点赞图标 -->
              <div @click="goodzan(item)">
                <van-icon
                  name="good-job-o"
                  size="25px"
                  :color="item.liked == true ? 'orange' : 'gray'"
                  click="iconfontzan"
                />
                <span>{{ item.likes }}</span>
              </div>
            </div>
          </div>
        </div>
      </van-pull-refresh>
    </div>

    <!-- 提问栏 -->
    <div class="footer">
      <input
        type="text"
        placeholder="请输入您要提问的问题，限一百字"
        v-model="quest"
        ref="quest"
      />
      <van-button
        type="danger"
        @click="provide"
        class="provide"
        :disabled="flag"
        color="skyblue"
        >提交</van-button
      >
    </div>
  </div>
</template>
<script>
import {
  getAnnualmeetinglist,
  getAnnualmeetingadd,
  getAnnualmeetingzan,
  getAnnualmeetingglay,
} from "@/api/annualmeeting";
import { Toast } from "vant";
export default {
  data() {
    return {
      list: [],
      quest: "",
      flag: false,
      show: false,
      txt: "",
      // showid:0,
      isLoading: false,
      arr: [],
      jobNum: 0,
      container: null,
    };
  },
  components: {},
  computed: {},
  methods: {
    //   获取列表接口显示列表
    async getAnnualmeeting() {
      let res = await getAnnualmeetinglist(
        "33C19222-B591-4A6D-AB13-BC5E61C8C018"
      );
      this.jobNum = 0;
      this.list = res.data.data;
      this.list.forEach((element) => {
        if (element.liked) {
          this.jobNum++;
        }
      });
      this.arr = this.list.sort(function (a, b) {
        return b.likes - a.likes;
      });
      //   console.log(this.list.data.data);
    },
    // 获取头标题
    async getAnnualmeetingglay() {
      let res = await getAnnualmeetingglay(
        "33C19222-B591-4A6D-AB13-BC5E61C8C018"
      );
      this.txt = res.data.data.title;
      // console.log(res.data);
    },
    // 提交新增数据，提交按钮
    async provide() {
      if (this.quest.trim().length <= 0) {
        this.$refs.quest.focus();
        return;
      }

      let obj = {
        annualMeetingId: "33C19222-B591-4A6D-AB13-BC5E61C8C018",
        title: this.quest,
      };
      let res = await getAnnualmeetingadd(obj);
      // 如果状态为true则渲染添加数据
      if (res.data.success) {
        //在这里调用刷新接口
        this.getAnnualmeeting();
        this.quest = "";
        this.flag = true;
      } else {
        // alert(res.data.errorMessage);
        Toast(res.data.errorMessage);
      }
    },
    // 点赞
    async goodzan(item) {
      if(item.liked){
        return;
      }
      // 获取点赞接口，需要传一个当前点击id
      if (this.jobNum < 3) {
        let res = await getAnnualmeetingzan(item.id);
        if (!res.data.success) {
          Toast(res.data.errorMessage);
        } else {
          this.jobNum++;
          this.list.forEach((element) => {
            if (element.id == item.id) {
              element.likes = element.likes + 1;
              element.liked = true;
              // Vue.set(element);
            }
          });
        }

        //this.getAnnualmeeting();
      } else {
        Toast("最多点赞三次");
      }

      // console.log(aaa);
      // console.log(id);
      // 调用数据接口重新渲染
      //this.getAnnualmeeting();
    },
    // 下拉刷新
    onRefresh() {
      setTimeout(() => {
        Toast("刷新成功");
        this.isLoading = false;
      }, 1000);
      this.getAnnualmeeting();
    },
  },
  created() {
    this.getAnnualmeeting();
    this.getAnnualmeetingglay();
  },
  mounted() {
    this.container = this.$refs.container;
  },
};
</script>
<style lang="less" scoped>
.top {
  width: 100vw;
  height: 15vw;
  text-align: center;
  line-height: 15vw;
  color: white;
  font-size: 25px;
  background-color: pink;
}
.footer {
  width: 100vw;
  padding-bottom: 20px;
  padding-top: 20px;
  background-color: pink;
  text-align: center;
  position: fixed;
  bottom: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  input {
    margin-right: 10px;
    padding-left: 5px;
    height: 30px;
    border-radius: 15px;
    width: 70%;
  }
  button {
    width: 20%;
    border-radius: 15px;
    color: white;
    background-color: rgba(98, 240, 98, 0.678);
    border: 0;
    padding-bottom: 5px;
    padding-top: 5px;
  }
}
.content-box {
  width: 100vw;
  overflow: scroll;
  height: 82vh;
  margin-bottom: 150px;
}
.showtable-box {
//   width: 100vw;
//   overflow: scroll;
//   height: 82vh;
//   margin-bottom: 150px;
margin-bottom: 80px;
 }
.showtable {
  width: 80vw;
  // height: 60px;
  margin: 0 auto;
  margin-top: 15px;
  border: 1px solid pink;
  border-radius: 15px;
  padding: 10px;
  padding-bottom: 40px;
  position: relative;
}
.question {
  margin-left: 25px;
  margin-top: 10px;
}
.zanicon {
  position: absolute;
  bottom: 5px;
  right: 10px;
}
.showw {
  display: none;
}
.questionn {
  margin-left: 20px;
  margin-top: 10px;
}
</style>
