import request from '@/utils/request'
// 列表接口数据
export function getAnnualmeetinglist(id) {
  return request({
    ///api/AnnualMeeting/{id}/Questions
    url: `/api/AnnualMeeting/${id}/Questions`,
    method: 'GET',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },
  })
}
// 新增提问数据接口
export function getAnnualmeetingadd(obj) {
  return request({
    url: `/api/AnnualMeetingQuestion`,
    data: obj,
    method: 'POST',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },
  })
}

// 点赞接口数据
export function getAnnualmeetingzan(id) {
  return request({
    url: `/api/AnnualMeetingQuestion/${id}/Feature`,
    method: 'PUT',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },
  })
}
// 单个数据接口
export function getAnnualmeetingglay(id) {
  return request({
    url: `/api/AnnualMeeting/${id}`,
    method: 'GET',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },
  })
}


